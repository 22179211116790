<template>
  <div v-loading="loading">
    <section>
      <article class="mt-5">
        <div class="container mt-5">

          <div
            class="mb-4"
            style="display: flex; align-items:center; justify-content: space-between;"
          >
            <h3>Users</h3>
            <button
              class="btn"
              v-if="$store.state.currentRole == 'ADMIN'"
              style="background-color: #083628; color: white;"
              @click="showAddUserModal"
            >
              Add User
            </button>
          </div>
          <em v-if="isLoading">Loading users...</em>
          <!-- <span v-if="users.error" class="text-danger">ERROR: {{ users.error }}</span>
          <div v-if="users.items"> -->

          <div class="search_add_section row">
            <div class="col-md-6">
              <input
                type="text"
                v-model.trim="filters[0].value"
                placeholder="Search by Name"
                class="search_by_input form-control"
                spellcheck="off"
              />
            </div>
            <div>
              <!-- <AddFacultyDialog @getFaculties="getFaculties()"/> -->
            </div>
          </div>
          <hr />

          <div
            v-loading="loading"
            class="table-responsive mb-5"
          >
            <data-tables
              :data="users"
              :table-props="tableProps"
              :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }"
              type="expand"
              :filters="filters"
            >

              <div slot="empty">
                <ScaleOut
                  v-if="loading && !loadingError"
                  class="mt-3"
                  :background="'#164B70'"
                />
                <div
                  @click="getReservations"
                  v-else-if="!loading && loadingError"
                  style="cursor: pointer"
                >
                  Unable to Load Users Now. Please click here to retry
                </div>
                <div v-if="!loading && !loadingError">No Users</div>
              </div>

              <el-table-column
                label="#"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
                width="80px"
              >
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Name"
                :show-overflow-tooltip="false"
                label-class-name="text-left"
                width="auto"
              >
                <template slot-scope="scope">
                  <span> {{ scope.row.fullname }} </span>
                </template>
              </el-table-column>

              <el-table-column
                label="User Info"
                :show-overflow-tooltip="false"
                label-class-name="text-left"
                class-name="text-left"
              >
                <template slot-scope="scope">
                  <span> <strong>Email:</strong> {{ scope.row.email }} </span><br />
                  <span> <strong>Username:</strong> {{ scope.row.username }} </span><br />
                  <span v-if="$store.state.currentRole == 'ADMIN'">
                    <strong>Password:</strong> {{ scope.row.password }}
                  </span>
                </template>
              </el-table-column>

              <el-table-column
                label="Created Date"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
              >
                <template slot-scope="scope">
                  <span> <strong>Date:</strong> {{ formattedDate(scope.row.date_created) }} </span><br />
                </template>
              </el-table-column>

              <el-table-column
                label="Role"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
              >
                <template slot-scope="scope">
                  <span style="color:green">
                    <small
                      class="text-success"
                      v-if="scope.row.role == 'ADMIN'"
                    >Admin</small>
                    <small
                      class="text-success"
                      v-else
                    >User</small>
                  </span>
                </template>
              </el-table-column>

              <el-table-column
                label="Actions"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
                v-if="$store.state.currentRole == 'ADMIN'"
              >
                <template slot-scope="scope">
                  <el-row style="display: flex; align-items:center; justify-content:center;">
                    <el-tooltip
                      content="Edit"
                      placement="top"
                    >
                      <el-button
                        type="warning"
                        :disabled="false"
                        @click="editUserData(scope.row)"
                        icon="el-icon-edit"
                        circle
                      ></el-button>
                    </el-tooltip>
                    <el-tooltip
                      content="Delete"
                      placement="top"
                    >
                      <el-button
                        type="danger"
                        :disabled="false"
                        @click="deleteUser(scope.row.id)"
                        icon="el-icon-delete"
                        circle
                      ></el-button>
                    </el-tooltip>
                  </el-row>
                </template>
              </el-table-column>

            </data-tables>
          </div>

        </div>
      </article>
    </section>

    <article>
      <section>
        <div class="courseUnits_dialogs">
          <el-dialog
            :title="`Add A User`"
            :visible.sync="addUserModal"
            :destroy-on-close="true"
            :before-close="handleClose"
            style="text-align: left"
          >
            <div v-loading="submitting">
              <el-form
                :model="userForm"
                :rules="rules"
                label-position="top"
                ref="userForm"
                class="demo-userForm2"
              >
                <div class="row">
                  <div class="col-md-6">
                    <label>Full Name</label>
                    <el-form-item prop="fullname">
                      <el-input v-model="userForm.fullname"></el-input>
                    </el-form-item>
                  </div>
                  <div class="col-md-6">
                    <label>Email</label>
                    <el-form-item prop="email">
                      <el-input v-model="userForm.email"></el-input>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <label>Username</label>
                    <el-form-item prop="username">
                      <el-input v-model="userForm.username"></el-input>
                    </el-form-item>
                  </div>

                  <div class="col-md-6">
                    <label>Password</label>
                    <el-form-item prop="password">
                      <el-input v-model="userForm.password"></el-input>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <label>Choose User Role</label><br />
                    <el-select
                      v-model="userForm.role"
                      placeholder="Select"
                      class="w-100"
                    >
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <label>User Photo</label><br />
                    <el-form-item>
                      <el-upload
                        class="upload-demo"
                        drag
                        action="''"
                        :on-change="handlePreview"
                        :on-remove="handleRemove"
                        :file-list="fileLists"
                        :auto-upload="false"
                        :multiple="false"
                      >

                        <div v-if="!isUploadingFile">
                          <i class="el-icon-upload"></i>
                          <div class="el-upload__text">
                            Drop file here or <em>click to upload</em>
                          </div>
                        </div>
                        <div v-else>
                          <el-progress
                            v-if="imageUploadingProgress != 100"
                            type="circle"
                            :color="progressColors"
                            :percentage="imageUploadingProgress"
                            :width="80"
                            :stroke-width="4"
                          ></el-progress>
                          <el-progress
                            v-else
                            type="circle"
                            :percentage="100"
                            status="success"
                            :width="80"
                            :stroke-width="4"
                          ></el-progress>
                          <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                            {{
                          imageUploadingProgress == 100
                            ? "Uploaded"
                            : "Uploading..."
                        }}
                          </div>
                        </div>
                        <!-- <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div> -->
                      </el-upload>
                    </el-form-item>
                  </div>
                </div>

                <hr />
                <el-form-item class="text-center">
                  <el-button
                    style="background-color: #083628; color: white;"
                    @click="addUser('userForm')"
                  >Add User</el-button>
                </el-form-item>
              </el-form>

              <div class="text-center">
                <div class="text-center">
                  <small>© 2022 Ihamba Adventures, All rights reserved.</small>
                </div>
                <div class="text-center">
                  <!--v-model="userForm.Course" <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                </div>
              </div>
            </div>
          </el-dialog>
        </div>
      </section>
    </article>

    <article>
      <section>
        <div class="courseUnits_dialogs">
          <el-dialog
            :title="`Edit A User`"
            :visible.sync="editUserModal"
            :destroy-on-close="true"
            :before-close="handleClose"
            style="text-align: left"
          >
            <div v-loading="submitting">
              <el-form
                :model="userForm"
                :rules="rules"
                ref="userForm"
                class="demo-userForm2"
              >
                <div class="row">
                  <div class="col-md-6">
                    <label>Full Name</label>
                    <el-form-item prop="fullname">
                      <el-input v-model="userForm.fullname"></el-input>
                    </el-form-item>
                  </div>
                  <div class="col-md-6">
                    <label>Email</label>
                    <el-form-item prop="email">
                      <el-input v-model="userForm.email"></el-input>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <label>Username</label>
                    <el-form-item prop="username">
                      <el-input v-model="userForm.username"></el-input>
                    </el-form-item>
                  </div>

                  <div class="col-md-6">
                    <label>Password</label>
                    <el-form-item prop="password">
                      <el-input v-model="userForm.password"></el-input>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <label>Choose User Role</label><br />
                    <el-select
                      v-model="userForm.role"
                      placeholder="Select"
                      class="w-100"
                    >
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>

                <hr />
                <el-form-item class="text-center">
                  <el-button
                    style="background-color: #996727; color: white;"
                    @click="editUser('userForm')"
                  >Edit User</el-button>
                </el-form-item>
              </el-form>

              <div class="text-center">
                <div class="text-center">
                  <small>© 2022 Ihamba Adventures, All rights reserved.</small>
                </div>
                <div class="text-center">
                  <!--v-model="userForm.Course" <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                </div>
              </div>
            </div>
          </el-dialog>
        </div>
      </section>
    </article>

  </div>
</template>

<script>
import { format, parseISO } from "date-fns";
import jwt_decode from "jwt-decode";
export default {
  data() {
    return {
      loading: false,
      isLoading: false,
      bookings: [],
      users: [],
      userEditId: "",

      options: [
        {
          value: "USER",
          label: "User",
        },
        {
          value: "ADMIN",
          label: "Admin",
        },
      ],

      tableData: [],
      tableProps: {
        border: true,
        stripe: true,
      },

      filters: [
        {
          prop: "fullname",
          value: "",
        },
      ],

      addUserModal: false,
      editUserModal: false,
      years: "",
      year_numbers: [],
      value: "",
      loadingError: false,
      submitting: false,
      course: "",
      userForm: {
        fullname: "",
        email: "",
        username: "",
        role: "",
        password: "",
      },

      rules: {
        fullname: [
          { required: true, message: "Full Name is required", trigger: "blur" },
        ],
        email: [
          { required: true, message: "Email is required", trigger: "blur" },
        ],
        username: [
          {
            required: true,
            message: "Username is required",
            trigger: "change",
          },
        ],
        password: [
          {
            required: true,
            message: "Password is required",
            trigger: "change",
          },
        ],
      },
    };
  },

  computed: {
    formattedDate() {
      return (dateString) => format(parseISO(dateString), "do MMM yyyy hh:mma");
    },
  },

  mounted() {
    this.getUsers();
  },

  methods: {
    handleClose(done) {
      done();
    },

    showAddUserModal() {
      this.addUserModal = true;
      this.userForm.title = "";
      this.userForm.course_unit_code = "";
      this.userForm.year_of_study = "";
      this.userForm.semester_of_study = "";
      this.userForm.course_id = this.course_id;
      this.userForm.credit_unit = "";
    },

    async getUsers() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(`api/user/get_all`);
        if (request.data.success) {
          // this.lecturerCourseUnits = request.data.lecturer_course_units;
          this.users = request.data.users;
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
        //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
        this.$notify({
          title: "Operation Failed",
          message: "Unable to fetch Users now, please try again",
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },

    async addUser(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.submitting = true;
            let response = await this.$http.post(
              `api/user/signup`,
              this.userForm
            );
            if (
              response.data.success &&
              response.data.message == "ACCOUNT_CREATED_SUCCESSFULLY"
            ) {
              this.$refs[formName].resetFields();
              this.getUsers();
              this.$notify({
                title: "Success",
                message: "User added successfully",
                type: "success",
              });
              this.addUserModal = false;
            } else {
              throw "UNEXPECTED_RESPONSE";
            }
          } catch (error) {
            if (error.message == "Network Error") {
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred, please try again.",
                type: "error",
              });
            }
            this.$notify({
              title: "Unable to add user",
              message: "An unexpected Error occurred, please try again",
              type: "error",
            });
          } finally {
            this.submitting = false;
          }
        } else {
          return false;
        }
      });
    },

    editUserData(userData) {
      this.editUserModal = true;
      (this.userForm.fullname = userData.fullname),
        (this.userForm.email = userData.email),
        (this.userForm.username = userData.username),
        (this.userForm.role = userData.role),
        (this.userForm.password = userData.password),
        (this.userEditId = userData.id);
    },

    async editUser(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.submitting = true;
            let response = await this.$http.post(
              `api/user/edit/${this.userEditId}`,
              this.userForm
            );
            if (
              response.data.success &&
              response.data.message == "USER_EDITED_SUCCESSFULLY"
            ) {
              this.$refs[formName].resetFields();
              this.getUsers();
              this.$notify({
                title: "Success",
                message: "User edited successfully",
                type: "success",
              });
              this.editUserModal = false;
            } else {
              throw "UNEXPECTED_RESPONSE";
            }
          } catch (error) {
            if (error.message == "Network Error") {
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred, please try again.",
                type: "error",
              });
            }
            this.$notify({
              title: "Unable to edit User",
              message: "An unexpected Error occurred, please try again",
              type: "error",
            });
          } finally {
            this.submitting = false;
          }
        } else {
          return false;
        }
      });
    },

    async deleteUser(id) {
      this.$confirm(
        // eslint-disable-line no-unreachable
        "This will permanently delete this User. Do you want to Continue?",
        "Confirm delete",
        {
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(async () => {
          try {
            this.deleting = true;
            let request = await this.$http.delete(`api/user/delete/${id}`);
            if (
              request.data.success &&
              request.data.message === "USER_DELETED_SUCCESSFULLY"
            ) {
              this.getUsers();
              this.$notify({
                title: "Success",
                message: "User deleted successfully!",
                type: "success",
              });
            } else {
              throw "UNEXPECTED_RESPONSE";
            }
          } catch (error) {
            if (error.message == "Network Error") {
              this.loadingError = true;
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred, please try again.",
                type: "error",
              });
            }
            this.$notify({
              title: "Unable to Delete",
              message: "Unable to delete the User, Please try again",
              type: "error",
            });
          } finally {
            this.deleting = false;
          }
        })
        .catch(() => {
          //
        });
    },
  },
};
</script>

<style>
.el-form-item {
  margin-bottom: 0px;
}

.el-form--label-top .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 0px;
}
label {
  display: inline-block;
  margin-bottom: 0px;
}
</style>